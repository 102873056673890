.label-on-left {
    --label-width: 7rem;
    --gap-width: 1rem;

    &+.label-on-left {
        margin-top: var(--sl-spacing-medium);
    }

    &::part(form-control) {
        display: grid;
        grid: auto / var(--label-width) 1fr;
        gap: var(--sl-spacing-3x-small) var(--gap-width);
        align-items: center;
    }

    &::part(form-control-label) {
        text-align: right;
    }

    &::part(form-control-help-text) {
        grid-column-start: 2;
    }
}