div.address-autocomplete {
  width: 100%;
  position: relative;

  > sl-menu {
    position: absolute;
    width: 100%;
    max-height: 225px;
    background: none;
    border: none;
    border-radius: 0px;
    background: var(--sl-panel-background-color);
    border-radius: var(--sl-border-radius-medium);
    border: solid var(--sl-panel-border-width) var(--sl-panel-border-color);
    z-index: 2;

    sl-menu-item {
      &::part(label) {
        font-size: 0.875rem;
      }
      &::part(checked-icon) {
        width: 4px;
      }
    }
  }

  label {
    font-size: 0.8125rem;
    font-weight: 400;
  }
}

.address-input {
  display: block;
  padding: 0.5rem;
  border: 1px solid #50545850;
  border-radius: 3px;
  outline: none;
  margin: 0 auto;
  font-size: 0.875rem;
  color: var(--sl-input-color);
  width: 100%;

  &:read-only {
    outline: none;
    border: none;
  }
}
