.so-button-group {
  --sl-color-primary-500: #377289;
  --sl-color-primary-600: #377289;
  --sl-color-neutral-600: #d8dcde;
  --sl-color-neutral-0: var(--sl-color-matte-blue);

  display: flex;
  align-items: center;
  margin-left: auto;
  flex-wrap: nowrap;

  button + button {
    margin-left: 0.5rem;
  }

  sl-icon-button {
    font-size: 1.25rem;

    &::part(base) {
      border-left: 1px solid #cfd9de;
      color: var(--sl-color-primary-600);
      margin-bottom: 8px;
      padding-left: 16px;
    }
  }

  sl-button {
    &[variant='primary'] {
      &::part(base) {
        color: #fff;
      }
    }

    &::part(base) {
      font-weight: 400;
      font-size: 0.8125rem;
    }
  }

  sl-menu-item {
    sl-icon {
      margin-right: 0.5rem;
    }

    &:hover {
      &::part(label) {
        color: #fff;
      }
    }

    &::part(label) {
      color: var(--sl-legacy-text-gray);
      font-size: 0.8125rem;
      display: flex;
      align-items: center;
    }
  }
}

.modal.show {
  display: block;
}

.sidebar-wrapper {
  height: 100%;
  display: flex;
}

.objects-nav {
  background: white;
  min-width: 236px;
  max-width: 236px;
  width: 236px;
  position: sticky;
  top: 64px;
  bottom: 0px;
  padding: 1rem 0.75rem 1rem 1rem;
  border-right: 1px solid #cfd9de;
  opacity: 1;
  overflow-y: hidden;
  max-height: calc(100vh - 64px);
  transition: width var(--sl-transition-medium), opacity var(--sl-transition-medium);
  display: flex;
  flex-direction: column;
  z-index: 2;

  &.hidden {
    padding: 0;
    width: 0;
    min-width: 0;
    max-width: 0;
    opacity: 0;
    overflow: hidden;
  }

  .search-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 1rem;

    sl-dropdown {
      user-select: none;

      sl-menu-item {
        &::part(base) {
          font-size: 0.875rem;

          &:hover {
            background-color: transparent;
            color: var(--sl-color-neutral-700);
          }
        }
      }
    }
  }

  .graph-tree {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
    margin-right: -12px; // to distance the scrollbar
    padding-right: 12px; // to distance the scrollbar
    margin-bottom: 12px;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
  }

  .add-new-object {
    display: flex;
    justify-content: center;
  }

  sl-input {
    --input-color: var(--sl-text-gray);
    --bg-color: #eceeee;

    --sl-input-background-color: var(--bg-color);
    --sl-input-background-color-hover: var(--bg-color);
    --sl-input-background-color-focus: var(--bg-color);
    --sl-input-border-color: #e8e8e8;
    --sl-input-border-color-focus: #e8e8e8;
    --sl-input-border-color-hover: #e8e8e8;
    --sl-input-color: var(--input-color);
    --sl-input-color-focus: var(--input-color);
    --sl-input-color-hover: var(--input-color);
    --sl-input-spacing-medium: 0.5em;
    --sl-focus-ring: none;

    width: 100%;

    sl-icon {
      --sl-input-icon-color: var(--input-color);
    }

    &::part(base) {
      border-radius: 8px;
      box-shadow: none;
    }
  }

  sl-tree sl-tree-item {
    --indent-size: 0.375rem;
    --sl-color-neutral-700: var(--sl-color-neutral-0);
    --sl-color-neutral-100: transparent;
    --sl-color-primary-600: transparent;

    sl-tree-item {
      font-weight: 400;

      &:not(:first-child) {
        margin-top: 0.2rem;
      }
    }

    sl-icon {
      &[slot='expand-icon'],
      &[slot='collapse-icon'] {
        width: 0.75rem;
      }
    }

    &::part(children) {
      cursor: default;
    }

    &::part(item) {
      color: var(--sl-color-matte-blue);
      position: relative;
      width: 100%;
    }

    &::part(label) {
      flex: 1;
      overflow: hidden;
      font-weight: 400;
      font-size: 0.875rem;
      margin-left: 0.5rem;
    }

    &::part(expand-button) {
      width: 20px;
      padding-left: 0px;
      padding-right: 0px;
      transform-origin: 30% 50%;
    }

    a {
      padding: 0px;
      width: 100%;
      color: inherit;
      text-decoration: none;
    }
  }

  .add-system-object-btn {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    font-size: 0.875rem;
    font-weight: 400;

    sl-icon {
      font-size: 1rem;
      margin-right: 0.5rem;
      color: $icon-color;
    }
  }

  .nav-item {
    position: relative;

    sl-icon {
      margin-right: 0.5rem;
      font-size: 1rem;
    }

    &::part(base) {
      cursor: default;
    }

    &::part(label) {
      cursor: pointer;
    }

    &.nav-leaf {
      &::part(expand-button) {
        cursor: default;
      }
    }

    > .icon {
      font-size: 1rem;
      &:not([data-default]) {
        cursor: default;
      }
    }

    &::part(item) {
      border-radius: 8px;

      &:hover {
        background-color: rgba(55, 114, 137, 0.1);
      }
    }

    &.active {
      color: $active-nav-color;

      a {
        cursor: default;
      }

      &::part(item) {
        background-color: #377289;
        color: $active-nav-color;
      }

      > .icon {
        color: $active-nav-color;
      }
    }

    a {
      padding: 0px;
      width: 100%;
      color: inherit;
      text-decoration: none;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      height: 30px;
      display: flex;
      align-items: center;

      > .icon {
        min-width: 1rem;
      }
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    sl-tooltip {
      --sl-tooltip-arrow-size: 0;
      --sl-tooltip-background-color: rgba(0, 0, 0, 0.8);
      --max-width: 225px;

      &::part(body) {
        font-size: 0.8125rem;
      }
    }
  }

  .navigator-category {
    .navigator-category--title {
      color: #377289;
      text-transform: uppercase;
      font-size: 0.8125rem;
      font-weight: 500;
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;

      > sl-icon {
        font-size: 1rem;
      }
    }

    > .icon {
      color: #377289;
    }
  }
}

.sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;

  .navbar-logo-section {
    height: 64px;
    padding: 1.25rem 0px;
    width: $drawer-width;
    border-bottom: 1px solid var(--sl-text-gray);
    background-color: var(--sl-background-blue);

    @media (max-width: 767px) {
      width: auto;
    }
  }

  .header-logo {
    max-height: 100%;
    max-width: 100%;
  }

  .sidebar-menu-top {
    display: flex;
    flex-direction: column;
  }

  .sidebar-menu-bottom {
    margin-top: auto;
    display: flex;
    flex-direction: column-reverse;

    sl-icon {
      &::part(base) {
        svg {
          width: auto;
          height: auto;
          margin: 0 auto;
          display: flex;
        }
      }
    }
  }
}

.navbar {
  border-bottom: 1px solid #cfd9de;
  background-color: white;
  padding: 0 1.25rem 0 1.25rem;
  display: flex;
  align-items: center;
  height: $nav-height;
  position: fixed;
  top: 0;
  right: 0;
  left: $drawer-width;
  z-index: 9999;
  gap: 1rem;
  flex-wrap: nowrap;

  @media (max-width: 767px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .navbar-items {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
  }

  .page-heading {
    color: var(--sl-color-matte-blue);
    display: flex;
    align-items: center;
    height: 100%;

    > div {
      height: 100%;
    }

    .active-page {
      display: flex;
      align-items: center;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 500;
      width: 216px;

      &:not(:only-child) {
        border-right: 1px solid #cfd9de;
        opacity: 1;

        &.hidden {
          width: 0px;
          opacity: 0;
          overflow: hidden;

          & + .active-object {
            padding-left: 0.25rem;

            .object-nav-toggle {
              left: -35px;
            }
          }
        }

        transition: width var(--sl-transition-medium), opacity var(--sl-transition-medium);
      }
    }

    .active-object {
      display: flex;
      align-items: center;
      padding-left: 1.25rem;
      position: relative;

      .ob-icon {
        display: flex;
        align-items: center;
        background-color: var(--sl-background-blue);
        color: #ffffff;
        width: 40px;
        height: 40px;
        border-radius: 5px;
        margin-right: 1rem;
        padding: 0.625rem;

        > sl-icon {
          border-radius: inherit;
          font-size: 1.25rem;
        }
      }

      .ob-meta {
        .ob-name {
          font-size: 1rem;
          display: block;
          font-weight: 500;
        }

        .ob-id {
          color: var(--sl-text-gray);
          font-size: 0.75rem;
          display: block;
        }
      }

      .object-nav-toggle {
        position: absolute;
        left: -14px;
        bottom: -14px;
        font-size: 0.75rem;
        background-color: #ffffff;
        box-shadow: 0px 1px 4px #00000029;
        border-radius: 100%;
      }
    }
  }

  .language-picker {
    max-width: 70px;
  }

  sl-input {
    --bg-color: #eceeee;
    --sl-input-filled-background-color: var(--bg-color);
    --sl-input-filled-background-color-focus: var(--bg-color);
    --sl-input-filled-background-color-hover: var(--bg-color);
    --sl-input-color: var(--sl-text-gray);
    --sl-input-color-hover: var(--sl-text-gray);
    --sl-input-placeholder-color: var(--sl-text-gray);
    --sl-focus-ring: none;

    height: 40px;
    min-width: 0;
    max-width: 400px;
    flex: 1;
    transition: all 0.5s;

    @media (max-width: 991px) {
      width: auto;
      flex-grow: 1;
      max-width: 113px;

      &:focus {
        max-width: 275px;
      }
    }

    &::part(prefix) {
      --sl-input-icon-color: var(--sl-text-gray);
    }
  }

  sl-select {
    --sl-input-background-color: transparent;
    --sl-input-color: var(--sl-text-gray);
    --sl-input-background-color-hover: transparent;
    --sl-input-background-color-focus: transparent;
    --sl-input-color-hover: var(--sl-text-gray);
    --sl-input-help-text-color: var(--sl-text-gray);
    --sl-input-spacing-small: 0.5rem;

    sl-option {
      &::part(base) {
        font-size: var(--sl-font-size-small);
      }
    }
  }

  sl-avatar {
    --size: 2.5rem;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 1rem;

    sl-icon-button {
      &::part(base) {
        font-size: 1.5rem;
        border-radius: 100%;
        border: 1px solid #cfd9de;
        height: 2.5rem;
        width: 2.5rem;
      }
    }
  }

  .profile-dropdown {
    sl-dropdown {
      user-select: none;

      sl-menu {
        text-align: center;

        .username {
          font-size: 0.875rem;
          margin-bottom: 0.25rem;
          color: var(--sl-color-matte-blue);
          font-weight: 600;
        }
      }

      sl-menu-item {
        &::part(base) {
          font-size: 0.875rem;
          color: var(--sl-color-matte-blue);

          &:hover {
            background-color: transparent;
            color: var(--sl-color-neutral-700);
          }
        }
      }
    }
  }

  sl-dropdown {
    sl-button {
      &::part(base) {
        font-size: var(--sl-font-size-x-small);
        background-color: transparent;
        color: var(--sl-color-neutral-0);
        border: none;
        text-transform: uppercase;
      }
    }
  }

  .search-sm {
    padding: 0 1rem 0;
    flex-grow: 1;
  }
}

.settings-icon-btn {
  font-size: 1rem;
  cursor: pointer;
}

sl-switch {
  --width: 34px;
  --height: calc(var(--width) / 2);
  --thumb-size: calc(var(--width) / 2);

  &::part(base) {
    font-size: 0.875rem;
  }
}

.launcher {
  position: relative;
  z-index: 1;

  .drawer {
    width: 0px;
    margin-left: -$drawer-width;
    height: calc(100% - 40px); //if showing footer
    height: 100%;
    bottom: 0px;
    top: 0px;
    left: 0px;
    position: fixed;
    opacity: 0;
    z-index: var(--sl-z-index-drawer);

    &.open {
      opacity: 1;
      margin-left: 0px;
      width: $drawer-width;
      background-color: var(--sl-background-blue);
      inset-inline: 0px auto;
    }

    transition: margin-left var(--sl-transition-medium), width var(--sl-transition-medium),
      opacity var(--sl-transition-medium);

    .overlay {
      display: block;
      position: fixed;
      inset: 0 0 0 240px;
      background-color: var(--sl-overlay-background-color);
      pointer-events: all;
    }
  }
}

sl-icon-button.hamburger-btn {
  font-size: 24px;
  --sl-color-neutral-600: #ffffff;
  --sl-color-primary-600: #ffffff;
  // margin: 0 0.5rem;
}

.logo-link {
  margin: auto;
}

.logo-wrapper {
  width: 50px;
  margin: 0 auto;

  @media (max-width: 767px) {
    width: 35px;
  }
}

.header-logo {
  box-sizing: content-box;
}

html {
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
}

footer {
  background: var(--sl-background-blue);
  color: white;
  text-align: center;
  padding: 0.5rem;
  font-weight: bold;
  height: 40px;
}

html,
body {
  height: 100%;
}

#app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@keyframes loading {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }

  100% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
  }
}

.backdrop-loader {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  pointer-events: none;
  touch-action: none;

  div.loading-logo {
    width: 100px;
    animation: loading 1s ease-in-out infinite alternate;
    -webkit-animation: loading 1s ease-in-out infinite alternate;
  }
}

.loading-indicator {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.initial-loading {
  display: flex !important;
  flex-direction: column;
  color: white;
  background: transparent linear-gradient(172deg, #59b593 0%, #377289 100%) 0% 0% no-repeat padding-box;

  .logo {
    background: url('#{$app-base-path}/img/whiteLogo.png') no-repeat;
    width: 280px;
    height: 50px;
    background-size: contain;
  }
}

.indeterminate-progress-bar {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  width: 390px;
  margin: 4rem 0;
  height: 0.25rem;
  position: relative;
  overflow: hidden;

  .indeterminate-progress-bar__progress {
    background-color: #3bd1f6;
    border-radius: 16px;
    position: absolute;
    bottom: 0;
    top: 0;
    width: 80%;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: indeterminate-progress-bar;
  }

  @keyframes indeterminate-progress-bar {
    from {
      left: -80%;
    }

    to {
      left: 100%;
    }
  }
}

sl-breadcrumb {
  sl-breadcrumb-item {
    &::part(separator) {
      margin: 0 var(--sl-spacing-2x-small);
    }

    &::part(base) {
      font-size: var(--sl-font-size-x-small);
    }

    &::part(label) {
      color: var(--sl-color-primary-600);
      font-weight: 600;
      line-height: normal;
      cursor: auto;
    }

    &:not(:last-of-type)::part(label) {
      font-weight: 500;
      color: $text-dark-gray;
      cursor: pointer;
    }
  }
}

.container-fluid {
  padding: 0 2.5rem;

  @media (max-width: 767px) {
    padding: 0 1rem;
  }
}

.breadcrumbs-container {
  background: #f4f6f8;
  padding: 0.75rem 2.5rem; //sync with padding of container fluid
  font-size: 0.8rem;

  @media (max-width: 767px) {
    padding: 0.5rem 1rem;
  }

  .breadcrumb {
    margin: 0;
  }
}

.nav-link {
  padding: 0;
}

.nav-item a {
  padding: 0 0.5rem;
  display: table-cell;
  vertical-align: middle;

  sl-icon {
    font-size: 1rem;
    width: 1rem;
  }
}

.nav-selectable {
  &.active {
    &::part(base) {
      cursor: default;
    }
  }
}

.nav-unselectable {
  &::part(base) {
    cursor: default;
  }

  > span {
    color: #aaa;
  }
}

.layout {
  flex-direction: column;
  display: flex;
  flex: 1 0 auto;
  padding: 1.25rem;
  padding-top: calc($nav-height + 1.25rem);
  width: 100%;
  margin-left: $drawer-width;
  width: calc(100% - $drawer-width);
  background-color: $body-bg;

  &.object-view {
    margin-left: calc($drawer-width + $ob-drawer-width);
    width: calc(100% - $drawer-width - $ob-drawer-width);

    @media (max-width: 767px) {
      margin-left: 0px;
      width: 100%;
    }
  }

  & > .container-fluid {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  transition: margin-left var(--sl-transition-medium), width var(--sl-transition-medium);
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.error {
  color: $error-color;
  font-weight: bold;
}

$tile-height: 70px;
$tile-width: 70px;
$tile-gap: 10px;

.tiles {
  flex: 1;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, 70px);
  grid-template-rows: repeat(auto-fit, 70px);
  grid-gap: 10px;
  overflow: visible;

  .tile {
    display: flex;
    position: relative;

    @for $i from 1 through 8 {
      &.cols-#{$i} {
        grid-column: span $i;
        width: $i * $tile-width + ($i - 1) * $tile-gap;
      }

      &.rows-#{$i} {
        grid-row: span $i;
        height: $i * $tile-width + ($i - 1) * $tile-gap;
      }
    }

    &:hover {
      outline: rgba(29, 29, 29, 0.1) solid 4px;
    }

    > a {
      display: flex;
      flex: 1;
    }

    .teaser {
      font-size: 1.2em;
      padding: 1em;
      background: #f8f9fa;
      flex: 1;
      display: flex;
    }
  }
}

.notifications {
  position: fixed;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  right: 2em;
  bottom: 2em;
  z-index: 10;

  .notifications-message {
    max-width: 800px;
    min-width: 400px;

    & + & {
      margin-top: 1em;
    }

    .alert {
      border-radius: 8px;
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      color: #ffffff;

      &.alert-success {
        --bs-alert-border-color: #43d590;
        background: linear-gradient(90deg, #38c77b 70%, #32bb71 100%);
      }

      &.alert-danger {
        --bs-alert-border-color: #f0863a;
        background: linear-gradient(90deg, #f6743e 0%, #d42525 100%);
      }

      &.alert-warning {
        --bs-alert-border-color: #ffdf8d;
        background: linear-gradient(90deg, #f8b806 75%, #fc9b24 100%);
      }

      &.alert-primary {
        --bs-alert-border-color: #7bcfed;
        background: linear-gradient(90deg, #329abb 0%, #2d82b2 100%);
      }

      &.alert-neutral {
        background: linear-gradient(90deg, #f6f6f6 0%, #f3f3f3 100%);
        border-color: #7a7a7a;
        color: #2f3f53;

        .alert-content,
        .alert-content .close-btn::part(base) {
          color: #2f3f53;
        }
      }

      sl-icon {
        font-size: 1.25rem;
        margin-left: 0.25rem;
      }

      .alert-content {
        margin-left: 1.25rem;

        .close-btn {
          position: absolute;
          top: 10px;
          right: 8px;
          font-size: 1rem;

          &::part(base) {
            color: #ffffff;
          }
        }
      }
    }

    .alert-heading {
      font-size: 0.9375rem;
      font-weight: 500;
      color: #ffffff;
    }
  }
}

.objects-container {
  border-radius: var(--rounded-radius);
  background-color: var(--card-bg);
  border: var(--card-border);
  padding: 1.25rem;

  * {
    animation: 0.15s ease-out 0s 1 fadeIn;
  }

  .tab-bar {
    width: 100%;
  }

  .nav-tabs {
    border-bottom: none;
    height: 36px;
    background: #ffffff;
    padding: 0 1rem;
    position: relative;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    width: 100%;
  }

  > sl-tab-group {
    position: relative;
    --track-width: 2px;
    --indicator-color: var(--sl-color-matte-blue);

    &::part(nav) {
      background-color: transparent;
      box-shadow: none;
      height: 36px;
    }

    &::part(tabs) {
      height: 40px;
      border-bottom: 1px solid rgba(80, 84, 88, 0.24);
    }

    &::part(active-tab-indicator) {
      display: none;
    }

    > sl-tab {
      cursor: pointer;
      height: 100%;
      display: flex;
      // align-items: center;

      &[active] {
        border-radius: 3px 3px 0 0;
        border-bottom: solid var(--track-width) var(--indicator-color);
      }

      &:not(:first-child) {
        margin-left: 1.75rem;

        &::part(active-tab-indicator) {
          margin-left: 1.75rem;
        }
      }

      &::part(base) {
        margin: 0px;
        padding: 0px;
        font-weight: 500;
        text-transform: uppercase;
      }

      .nav-tab > a {
        position: relative;
        > div {
          display: flex;
          align-items: center;
        }
      }

      .add-app {
        display: flex;
        align-items: center;
        color: #007dab;
        font-weight: 500;

        sl-icon {
          margin-right: 0.5rem;
        }
      }
    }

    > sl-tab-panel {
      --padding: 1rem 0 0;
    }
  }

  .dashboards {
    display: flex;
    align-items: flex-start;

    sl-icon {
      font-size: 1.25rem;
      margin-right: 0.5rem;
    }
  }

  .application-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    font-weight: 500;
    font-size: 0.8125rem;
    text-transform: uppercase;
    color: var(--sl-text-gray);

    > sl-icon {
      font-size: 1.25rem;
      border: 1px dashed #53647199;
      border-radius: 4px;
      margin-right: 0.5rem;
      background-color: #f4f4f4;
    }
  }

  .applications {
    .status-label {
      font-weight: 500;
      font-size: 0.8125rem;
      color: var(--sl-color-matte-blue);
    }

    .filter-bar {
      display: flex;
      align-items: center;
      margin: 1.25rem 0;

      sl-input {
        --sl-input-height-medium: 36px;
        --sl-input-background-color: #eceeee;
        --sl-input-border-color: #e8e8e8;
        --sl-input-background-color-hover: #eceeee;
        --sl-input-border-color-hover: #e8e8e8;
        --sl-input-background-color-focus: #eceeee;
        --sl-input-border-color-focus: #e8e8e8;
        --sl-input-border-radius-medium: 8px;
      }
    }

    .wrapper {
      display: flex;
      align-items: center;

      .app-icon {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: #ececec;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .app-meta {
        width: calc(100% - 55px);
        margin-left: 0.5rem;

        .app-name {
          color: #3d5259;
          font-size: 0.8125rem;
          font-weight: 500;
        }

        .app-desc {
          color: #536471;
          font-size: 0.6875rem;
        }
      }
    }

    .app-action {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      justify-content: flex-end;
      color: $background-blue-color;
      font-size: 0.8125rem;
      font-weight: 500;

      span {
        margin-right: 1rem;
      }

      .install-btn,
      .open-btn {
        border: 1px solid $background-blue-color;
        outline: none;
        border-radius: 8px;
        color: $background-blue-color;
        background: transparent;
        cursor: pointer;
      }
    }

    .app-list {
      .app-list-item {
        width: 100%;
        margin-top: 1.25rem;

        &::part(base) {
          width: 100%;
          border-radius: $round-radius;
        }

        &::part(body) {
          padding: 0.25rem 0.5rem 0.25rem 1rem;
        }

        .wrapper {
          .app-meta {
            display: flex;
            align-items: center;
          }

          .app-desc {
            font-size: 0.75rem;
          }
        }

        .app-action {
          margin: 0px;

          sl-icon-button {
            font-size: 1.25rem;
            margin-left: 0.75rem;

            &::part(base) {
              outline: none;
              color: $background-blue-color;
            }
          }

          sl-menu-item {
            &::part(base) {
              font-size: 0.8125rem;
            }
          }
        }
      }
    }

    .app-cards {
      display: flex;
      flex-wrap: wrap;
      overflow: auto;
      margin: 1rem 0;
      gap: 1.25rem;
      cursor: default;

      .app-card {
        display: inline-flex;
        width: calc(25% - 1.25rem);

        &::part(base) {
          width: 100%;
          border-radius: $round-radius;
        }

        &::part(body) {
          padding: 0.625rem 1rem;
        }
      }
    }

    sl-button,
    sl-icon {
      &::part(base) {
        color: $text-dark-gray;
      }
    }
  }

  .tab-content {
    // margin: 1rem 0;
    border-radius: $border-radius;
  }
}

.nav-tabs .nav-link.disabled::before {
  content: '+';
}

sl-dialog {
  &::part(base) {
    z-index: 99999;
  }

  &::part(title) {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0px;
    padding-bottom: 0px;
    color: #3d5259;
  }

  &::part(body) {
    color: var(--sl-text-gray);
    font-size: 0.875rem;
  }

  &::part(close-button) {
    padding-top: 0.75rem;
  }

  .actions sl-button + sl-button {
    margin-left: 0.5rem;
  }
}

.search-so {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;

  sl-input {
    width: 100%;

    &::part(base) {
      border-width: 0px 0px 1px;
      border-radius: 0px;
      box-shadow: none;
    }
  }
}

.tree-view-context-menu {
  sl-menu-item {
    sl-icon {
      margin-right: 0.5rem;
    }

    &:hover {
      &::part(label) {
        color: #fff;
      }
    }

    &::part(label) {
      color: var(--sl-legacy-text-gray);
      font-size: 0.8125rem;
      display: flex;
      align-items: center;
    }
  }
}

.so-tree {
  max-height: 500px;
  overflow-y: auto;

  sl-tree-item {
    --indent-size: 0.375rem;
    font-weight: 400;

    &::part(item) {
      position: relative;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    &::part(label) {
      width: 100%;
      font-weight: 400;
      font-size: 0.875rem;
    }

    &.nav-item[expanded]:focus {
      &::part(base) {
        background-color: var(--sl-color-neutral-100);
      }
    }

    &::part(expand-button) {
      cursor: default;
    }
  }
}

.filter-settings {
  .setting-block {
    margin-bottom: 0.75rem;

    .sub-heading {
      margin-bottom: 4px;
    }

    sl-radio {
      &::part(base) {
        font-size: 0.875rem;
      }
    }
    sl-select {
      --sl-input-label-font-size-medium: 0.875rem;
      --sl-input-font-size-medium: 0.875rem;

      sl-option {
        &::part(base) {
          font-size: var(--sl-font-size-small);
        }
      }
    }
  }
}

.so-dialog-label {
  color: var(--sl-color-neutral-600);
  text-align: center;
  margin-right: calc(-2.5 * var(--header-spacing));
}

.so-dialog-content {
  border-top: 1px solid #919aa366;
  margin: calc(-0.5 * var(--body-spacing)) calc(-1 * var(--body-spacing)) 0;
  padding: calc(0.5 * var(--body-spacing)) var(--body-spacing) 0;
}

.so-dialog-actions {
  border-top: 1px solid #919aa366;
  // negative  margin to cover existing padding and then applying padding for UI
  margin: 0 calc(-1 * var(--footer-spacing)) -0.625rem;
  padding: 0 0.625rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  sl-tooltip {
    --sl-tooltip-arrow-size: 0;
    &::part(body) {
      font-size: 0.75rem;
    }
  }

  sl-button {
    margin-top: 0.625rem;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    &[variant='text'] {
      margin-right: auto;
    }
  }
}

/* Permissions Dialog */
sl-dialog:has(div.permissions-label) {
  --width: 43rem;

  &::part(close-button) {
    padding: 0.5rem 0.4375rem 0;
  }

  &::part(body) {
    padding: 0.875rem 1.25rem 0px;
  }

  &::part(footer) {
    padding: 0 var(--body-spacing) 0.625rem;
  }
}

.permissions-label {
  display: flex;
  align-items: center;
  justify-content: left;
  color: $matte-blue-color;

  sl-icon {
    margin-right: 0.5rem;
  }
}

.permissions-content {
  --border-color: #919aa366;

  .disabled-inputs {
    opacity: 0.6;
    cursor: default;
  }

  sl-button {
    &::part(base) {
      font-weight: normal;
    }
  }

  sl-input[disabled] {
    --sl-input-color-disabled: rgba(0, 0, 0, 0.8);

    &::part(base) {
      @extend .disabled-inputs;
    }
  }

  sl-select[disabled] {
    --sl-input-help-text-color: rgba(0, 0, 0, 0.8);

    &::part(control) {
      @extend .disabled-inputs;
    }
  }

  .search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    sl-input {
      width: 100%;
      margin-right: 1rem;

      &::part(base) {
        border-width: 0px 0px 1px;
        border-radius: 0px;
        box-shadow: none;
      }
    }
  }

  .permissions-list {
    border-radius: 3px;
    border: 1px solid var(--border-color);
    overflow: auto;
    height: 350px;

    .permission {
      width: 100%;
      display: flex;
      align-items: center;
      height: 40px;
      font-size: 0.8125rem;
      font-weight: 500;
      border-bottom: 1px solid var(--border-color);

      .selector {
        padding: 0 0.625rem;
        border-right: 1px dashed var(--border-color);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-right: 1rem;

        sl-checkbox::part(label) {
          margin-inline-start: 0px;
        }
      }

      sl-icon {
        margin: 0 0.625rem;
        font-size: 1rem;

        &[name='chevron-down'],
        &[name='chevron-up'] {
          width: 2rem;
          margin: 0px;
        }
      }

      > span {
        flex: 1;
      }

      .last-block-perm {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px dashed var(--border-color);
      }

      sl-icon-button {
        @extend .last-block-perm;
        font-size: 1rem;
      }

      sl-menu {
        padding: 0;
      }

      sl-menu-item {
        &::part(label) {
          display: flex;
          align-items: center;
        }

        sl-icon {
          margin-right: 0.5rem;
        }

        &.remove {
          &::part(base) {
            color: #ab0614;
          }

          &:hover:not([aria-disabled='true']) {
            &::part(base) {
              color: var(--sl-color-neutral-0);
              background-color: #ab0614;
            }
          }
        }

        &::part(base) {
          font-weight: 500;
          font-size: 0.8125rem;
        }

        &:not(:last-child) {
          border-bottom: 1px solid var(--border-color);
        }
      }

      .perm-action {
        position: relative;
        width: 2rem;
        height: 100%;

        > span {
          cursor: default;
        }

        sl-icon-button {
          --sl-spacing-x-small: 0.375rem;
        }
      }

      .inherited-I {
        @extend .last-block-perm;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.5rem;
        font-family: 'Times New Roman', Times, serif;
        padding: 0 0.625rem;
        flex: 0;
      }

      sl-icon-button.navigate-btn {
        border-left: none;
        margin-right: 1rem;

        &.inherited {
          margin-right: 1.1rem;
        }

        &::part(base) {
          color: #007dab;
          font-size: 1rem;
          background-color: #0000000d;
        }
      }

      .popup {
        position: absolute;
        right: 15px;
        top: 25px;
        background-color: #ffffff;
        z-index: 10;
      }
    }

    sl-menu-item {
      &::part(checked-icon) {
        width: 0px;
      }
    }

    .nested-permission {
      user-select: none;

      sl-icon {
        margin: 0 0.625rem;
        font-size: 1rem;
      }

      .summary {
        cursor: pointer;

        .permission {
          display: flex;
          align-items: center;
        }
      }

      .nested-list {
        height: auto;
        transition: height 500ms ease-in;

        .indentation {
          height: 40px;
          width: 2.35rem;
          background-color: #f6f6f6;
          margin: 0px;
        }
      }
    }
  }
}

.permissions-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  sl-checkbox::part(base) {
    font-size: 0.8125rem;
    color: $text-gray;
    font-weight: 500;
  }

  sl-button {
    margin-top: 0.625rem;

    &::part(base) {
      font-weight: normal;
    }

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  .actions {
    margin-left: auto;
  }
}

/* Permissions Styles Ends */

sl-dialog:has(div.so-information) {
  --width: 53.125rem;
  --sl-panel-background-color: #f4f6f8;
  --body-spacing: 1rem;

  &::part(header) {
    display: none;
  }

  &::part(body) {
    padding-top: 0;
  }
}

.so-information {
  .breadcrumbs {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding-left: 1rem;

    sl-button {
      --sl-color-primary-600: #007adb;
      --sl-color-primary-500: #0061af;
    }

    sl-icon {
      font-size: 0.75rem;
    }

    span {
      font-weight: 600;
      color: #161c24;
    }
  }

  .so-content {
    width: 100%;

    .generalInfo {
      background-color: #ffffff;
      border-top: 1px solid #919aa366;
      border-bottom: 1px solid #919aa366;
      padding: 1rem;
      margin: 0 -1rem;

      .accordion-summary {
        justify-content: space-between;
      }
    }

    .content {
      box-shadow: none;
      border: 1px solid #919aa366;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem 0 0;

    sl-button {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
}

.scheduler {
  border: 1px solid var(--border-color);
  border-radius: 3px;

  .schedule-breadcrumbs {
    display: flex;
    align-items: center;
    background-color: #f6f6f6;
    padding: 0.5rem 1rem;

    --sl-font-size-small: 0.8125rem;
    --sl-font-weight-semibold: 500;

    > sl-icon {
      cursor: pointer;
    }

    sl-breadcrumb {
      margin-left: 0.5rem;

      sl-breadcrumb-item::part(label) {
        cursor: default;
      }
    }
  }

  .schedule-wrapper {
    padding: 1rem 1.25rem;
  }

  .schedule-rrule {
    padding: var(--sl-spacing-medium) 0.5rem 0;
    border-top: 1px solid var(--border-color);
    margin-top: var(--sl-spacing-medium);

    .label-left {
      --label-width: 4.4rem;
    }
  }

  .start {
    display: flex;
    margin-top: var(--sl-spacing-medium);
    width: 100%;

    .dt-start {
      display: flex;
      flex-direction: column;
    }

    .allDay {
      margin: 0.5rem 0 0 6.3rem;
    }

    .dt-end {
      --label-width: 3.4rem;
    }
  }
}

.label-left {
  --label-width: 5rem;
  --gap-width: 1.25rem;

  &::part(form-control) {
    display: grid;
    grid: auto / var(--label-width) 1fr;
    gap: var(--sl-spacing-3x-small) var(--gap-width);
    align-items: center;
  }

  &::part(form-control-label) {
    text-align: right;
    font-weight: 500;
    font-size: 0.8125rem;
  }

  &::part(form-control-help-text) {
    grid-column: span 2;
    padding-left: calc(var(--label-width) + var(--gap-width));
  }
}

.label-left + .label-left {
  margin-top: var(--sl-spacing-medium);
}

.rrule-generator {
  color: $text-gray;

  sl-menu-item {
    &::part(base) {
      font-size: 0.875rem;
    }
  }

  sl-select {
    &::part(display-label) {
      color: #161c24;
      font-weight: 500;
      font-size: 0.875rem;
    }
  }

  sl-input {
    &::part(input) {
      color: #161c24;
      font-weight: 500;
      font-size: 0.875rem;
    }
  }

  .start,
  .end {
    margin-top: var(--sl-spacing-medium);
    display: flex;
    align-items: center;
    gap: 0.625rem;

    sl-switch {
      margin-left: 0.5rem;
    }
  }

  .rrule-selector {
    display: flex;
    align-items: center;

    sl-select {
      min-width: 90px;

      &::part(menu) {
        min-width: 90px !important;
        width: auto !important;
      }
    }

    .rrule {
      display: flex;
      align-items: center;

      > * {
        &:first-child {
          margin-left: var(--sl-spacing-small);
        }

        &:not(:last-child) {
          margin-right: var(--sl-spacing-small);
        }
      }
    }
  }

  .day-selector {
    &::part(menu) {
      width: 105px !important;
    }
  }

  .ord-selector {
    &::part(menu) {
      width: 115px !important;
    }
  }

  .recurrence-input {
    width: 5rem;
  }

  .common-rrule {
    display: flex;
    align-items: center;
    margin-top: var(--sl-spacing-medium);
    margin-left: 6.25rem; //5rem + 1.25rem
  }

  .weekly {
    @extend .common-rrule;
  }

  .monthly {
    @extend .common-rrule;

    sl-radio {
      --sl-input-font-size-medium: 0.8125rem;
      --sl-input-font-weight: 500;
      width: 80px;
    }

    .flex {
      > sl-radio-group {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        > sl-radio {
          padding: 0.625rem 0;

          &:first-child {
            margin-bottom: 0.75rem;
          }
        }
      }

      sl-select {
        min-width: 90px;

        &::part(menu) {
          min-width: 90px !important;
          width: auto !important;
        }
      }

      sl-select + sl-select {
        margin-left: var(--sl-spacing-medium);
      }

      > .flex div:not(:first-child) {
        margin: 0.75rem 0 0;
      }
    }
  }

  .yearly {
    @extend .monthly;

    .flex {
      > span {
        margin: 0 var(--sl-spacing-medium);
      }
    }
  }

  .flex {
    display: flex;
    align-items: center;

    &.column {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .rule {
    font-weight: 500;
    font-size: 0.8125rem;
    padding: 1.25rem 0 0 6.25rem;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.schedule-footer {
  display: flex;

  .actions {
    margin-left: auto;
    margin-top: 0.625rem;

    :not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.required {
  &::part(form-control-label):after {
    content: '*';
    color: var(--sl-color-danger-500);
    font-weight: bold;
  }
}

@media screen and (max-width: 991px) {
  .objects-nav {
    position: absolute;
    z-index: 2;
    width: 250px;
    min-width: 0;
    max-width: none;
  }
  .navbar {
    .page-heading {
      .active-page {
        &.active-page-sidebar {
          width: 230px;
          position: absolute;
          z-index: 1;
          background: #fff;
        }
      }
      .active-object {
        padding-left: 0.25rem;
        .object-nav-toggle {
          left: 215px;
          z-index: 1;
          transition: left var(--sl-transition-medium), opacity var(--sl-transition-medium);
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .launcher {
    .drawer {
      &.open {
        width: 60px;
      }
    }
  }
  .sidebar {
    width: 100%;
  }
  .objects-nav {
    left: 60px;
  }
  .navbar {
    left: 60px;
    padding-left: 1.25rem;
    .page-heading {
      .active-page {
        width: auto;
      }
      .active-object {
        .ob-icon {
          width: 30px;
          height: 30px;
          justify-content: center;
          padding: 0;
        }
      }
    }
  }
  .layout {
    &.launcher-active {
      margin-left: 60px;
      width: calc(100% - 60px);
    }
  }
}

.tz-tooltip {
  --sl-tooltip-arrow-size: 3px;
}

.timezone-selector {
  max-width: 150px;

  &::part(listbox) {
    margin-left: -30px;
    width: 200px;
    max-width: 200px;
    max-height: 400px;
  }
}
