.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-width: 0 1px 1px 1px;
}

.two-column-container {
  display: flex;
  flex-direction: row;
  margin: -1.25rem;
  flex: 1;

  > .container-content {
    flex: 1;
    margin: 1rem 1.25rem;
    width: calc(100% - 236px - 40px); //236 - objects nav, 40 - sidebar
  }
}

.icon-selector {
  sl-dialog {
    --width: 54rem;

    sl-tab {
      &::part(base) {
        padding-top: 0px;
      }
    }

    sl-icon-button[slot='nav'] {
      &::part(base) {
        padding-top: 0px;
        margin-top: -4px;
        font-size: 1.5rem;
      }
    }
  }

  .icon-selector--icon {
    color: #536471;
    font-size: 1.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    cursor: pointer;
    margin-bottom: 0.25rem;

    &[aria-selected='true'] {
      color: #377289;
      background-color: #eceeee;
      border-radius: 8px;
    }

    .icon-name {
      font-size: 0.75rem;
      margin-top: 0.25rem;
      text-align: center;
    }
  }

  .icon-selector--body {
    display: flex;
    justify-content: space-between;

    .icon-grid-wrapper {
      overflow: hidden;
      display: flex;
      position: relative;

      sl-tab-group {
        width: 95%;
      }

      sl-icon-button {
        font-size: 1.25rem;
        position: absolute;
        right: -8px;
        top: -8px;
      }
    }
  }

  .icon-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 1fr);
    height: 260px;
    overflow: auto;
    border: 1px solid #d8dcde;
    border-radius: 4px;
    padding: 0.5rem;
  }

  .selected-icon-view {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2.5rem 0 2rem;

    .selected-icon {
      height: 120px;
      width: 120px;
      display: flex;
      background-color: #eceeee;
      box-shadow: -2px -2px 0px #d8dcde;
      border-radius: 0.5rem;

      > sl-icon {
        font-size: 2.5rem;
        color: #536471;
        margin: auto;
        transform: translateX(-1px);
      }
    }
  }

  .is-label {
    font-size: 1rem;
    font-weight: 500;
    color: #3d5259;

    > span {
      color: #536471;
      font-size: 0.75rem;
      font-weight: normal;
      display: block;
    }
  }

  .use-default-btn {
    margin: 1rem auto 1.5rem;
  }

  .action-btns {
    display: flex;
    align-items: center;

    sl-button {
      --sl-color-primary-500: rgb(61, 82, 89, 0.9);

      & + sl-button {
        margin-left: 0.5rem;
      }
    }
  }
}

.heading {
  --sl-input-font-size-medium: 1.25rem;
  --sl-input-font-weight: 500;
  font-size: 1.25rem;
  color: inherit;
  margin: 0 1.25rem 0 1rem;
}

.sub-heading {
  color: var(--sl-text-gray);
  margin-bottom: 12px;
  font-size: 0.875rem;
  font-weight: 500;
}

.info-section {
  margin-bottom: 20px;

  .sub-heading {
    margin-bottom: 12px;
    font-size: 0.875rem;
  }
}

.nature-icons {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  sl-tooltip {
    --sl-tooltip-background-color: rgba(0, 0, 0, 0.8);

    &::part(body) {
      font-size: 0.75rem;
      background-color: rgba(0, 0, 0, 0.8);
      text-transform: capitalize;
    }
  }

  .nature-icon {
    display: flex;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #377289;
    padding: 6px;

    sl-icon {
      &::part(base) {
        color: #505458;
      }
    }
  }
}

.action-btn {
  &:not(:last-child) {
    margin-right: 0.625rem;
  }

  height: 38px;
}

.so-edit-icon {
  position: relative;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--sl-color-primary-600);

  > i {
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    color: #fff;
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.so-title {
  display: flex;
  align-items: center;
  height: 60px;
}

.so-labels {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0 0.5rem;
}

.dialogFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  sl-button {
    &:not(:last-child) {
      margin-right: 0.625rem;
    }
  }
}

.greeting-tile {
  > img {
    width: 100%;
    height: 100%;
  }

  .windmill {
    position: absolute;
    bottom: -17%;
    right: -32px;
    width: 60% !important;
  }

  .stars {
    position: absolute;
    top: -35%;
    left: 9%;
    width: 69% !important;
  }

  .clouds {
    position: absolute;
    top: -17%;
    left: 0%;
  }

  .greet {
    color: #ffffff;
    text-transform: uppercase;
    position: absolute;
    left: 5%;
    bottom: 12%;
    font-size: 1.25rem;
    font-weight: 600;

    span.welcome-text {
      font-weight: 500;
      font-size: 0.75rem;
    }
  }
}

.dashboard-container {
  width: 100%;
  grid-template-columns: repeat(32, 1fr);
  grid-template-rows: repeat(auto-fill, 40px);
  display: grid;
  grid-auto-flow: row;
  gap: 18px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fill, 1.25%);
    grid-template-rows: repeat(auto-fill, 16px);
  }

  @media (max-width: 991px) {
    grid-template-columns: auto;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, 100%);
    grid-template-rows: auto;
  }

  .dashboard-tile {
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
    border: var(--card-border);
    user-select: none;
    display: flex;
    flex-direction: column;

    @media (max-width: 991px) {
      grid-column-end: span 25 !important;
    }

    @media (max-width: 767px) {
      grid-row-end: auto !important;
      grid-column-end: span 1 !important;
    }
  }
}

.image-viewer-container {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  margin-top: auto;
}

.image-viewer {
  border: var(--card-border);
  border-radius: var(--rounded-radius);
  margin-top: auto;

  sl-dialog {
    --sl-overlay-background-color: rgba(0, 0, 0, 0.5);
    --sl-panel-background-color: transparent;

    &::part(panel) {
      height: 100%;
      width: 100%;
    }

    &::part(body) {
      padding: 0px;
    }

    @media (min-width: 1400px) {
      &::part(panel) {
        width: 80%;
      }
    }
  }

  .fs-close-button {
    position: absolute;
    right: 32px;
    top: 20px;
    font-size: 24px;
    color: #ffffff;
    cursor: pointer;
  }

  .card-wrapper {
    padding: 0px;
    flex: 0;
    border-radius: var(--rounded-radius);
  }

  .images {
    padding: 0.5rem;
    display: flex;

    > .image {
      width: 80px;
      height: 80px;
      border-radius: var(--rounded-radius);
      position: relative;
      cursor: pointer;

      &:not(:first-child) {
        margin-left: 0.75rem;
      }

      .img-overlay {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.3);
        color: white;
        font-weight: 600;
        border-radius: var(--rounded-radius);
        width: 100%;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.875rem;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: var(--rounded-radius);
      }
    }
  }

  .full-image-view {
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .images {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: auto;

      .active {
        outline: var(--sl-focus-ring);
        outline-offset: var(--sl-focus-ring-offset);
      }
    }

    .image-view {
      margin-top: auto;
      max-height: 700px;
      display: flex;
      justify-content: center;
      align-items: center;

      sl-icon-button {
        --sl-color-neutral-600: #fff;
        --sl-color-primary-600: #fff;
        --sl-color-primary-700: #fff;
        font-size: 3rem;

        &::part(base):hover {
          color: rgba(255, 255, 255, 0.8);
        }
      }

      img {
        height: 100%;
        width: 800px;
        margin: 0 50px;
      }
    }
  }
}

.descendant-objects {
  padding: 1rem;
  border: var(--card-border);
  border-radius: var(--rounded-radius);

  > .heading {
    font-size: 0.8125rem;
    color: var(--sl-text-gray);
    margin: 0 0 1rem;
  }

  .nature-icon {
    border-color: #d8dcde;
  }

  .table-wrapper {
    table {
      border-radius: 4px;
      border: 1px solid #d8dcde;
      border-collapse: collapse;
    }

    thead {
      background-color: #eceeee;
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid #d8dcde;
    }

    th {
      padding: 11px 16px 10px;
      font-size: 0.8125rem;
    }

    td {
      padding: 11px 16px 10px;

      &:first-child {
        font-weight: 500;
        color: var(--sl-color-matte-blue);
      }
    }

    tr {
      border: 1px solid #d8dcde;
    }
  }
}

@media screen and (max-width: 991px) {
  .two-column-container {
    > .container-content {
      width: calc(100% - 120px);
      margin: 1rem;
    }
  }
}

@media screen and (max-width: 767px) {
  .dashboard-container {
    .dashboard-tile {
      .greeting-tile-main {
        height: 140px;
      }
      img {
        height: 140px;
        object-fit: cover;
      }
      .windmill {
        bottom: -10%;
      }
    }
  }
}

.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;

  h5 {
    margin-top: 2rem;
  }

  h6 {
    margin-top: 1rem;
  }
}

.notifications-feed {
  --border-radius: 8px;
  --padding: 0px;

  border-radius: 8px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  min-width: 450px;
  max-width: 450px;

  h5 {
    font-size: 1rem;
    color: #3d5259;
    font-weight: 500;
    margin: 0;
  }

  sl-divider {
    margin: 0;
  }

  .notification-heading {
    padding: 1rem;
  }
  .notification-feed-list {
    padding: 1rem;
    max-height: 500px;
    overflow: auto;

    > span {
      display: block;
      text-align: center;
    }
  }

  .notification-feed-item {
    display: flex;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .notification-icon {
      font-size: 2.5rem;
    }

    .notification-content {
      margin-left: 1rem;
    }

    .notification-title {
      font-size: 0.8125rem;
      color: #3d5259;
      font-weight: 500;
    }

    .notification-body {
      font-size: 0.75rem;
      color: #788da4;
      margin: 0px;
    }
    .notification-time {
      font-size: 11px;
      color: #536471;
    }
  }
}

.error-boundary {
  height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .error-animation {
    width: 400px;
    height: 400px;
  }

  .error-head-text {
    font-size: 1.625rem;
    font-weight: 500;
    color: #0f1419;
    margin-top: 1rem;
  }

  .error-body-text {
    color: #536471;
    font-size: 1rem;
    max-width: 800px;
    word-wrap: break-word;
    margin: 1.5rem 0;
  }
}

.component-error-boundary {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: var(--card-border);
  border-radius: var(--rounded-radius);
  height: 300px;

  .component-error-head-text {
    font-size: 1.375rem;
    font-weight: 500;
    color: #0f1419;
    margin-top: 1rem;
  }

  .component-error-body-text {
    color: #536471;
    font-size: 1rem;
    max-width: 800px;
    word-wrap: break-word;
    margin: 1.5rem 0;
  }
}

.error-boundary,
.component-error-boundary {
  sl-button {
    &::part(base) {
      border-radius: 10px;
    }
  }
}
