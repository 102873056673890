@font-face {
  font-family: 'Poppins';
  src: url('#{$app-base-path}/fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('#{$app-base-path}/fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('#{$app-base-path}/fonts/Poppins-Regular.woff2') format('woff2'),
    url('#{$app-base-path}/fonts/Poppins-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('#{$app-base-path}/fonts/Poppins-ExtraBold.woff2') format('woff2'),
    url('#{$app-base-path}/fonts/Poppins-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('#{$app-base-path}/fonts/Poppins-Medium.woff2') format('woff2'),
    url('#{$app-base-path}/fonts/Poppins-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('#{$app-base-path}/fonts/Poppins-ExtraLight.woff2') format('woff2'),
    url('#{$app-base-path}/fonts/Poppins-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('#{$app-base-path}/fonts/Poppins-Thin.woff2') format('woff2'),
    url('#{$app-base-path}/fonts/Poppins-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('#{$app-base-path}/fonts/Poppins-Light.woff2') format('woff2'),
    url('#{$app-base-path}/fonts/Poppins-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('#{$app-base-path}/fonts/Poppins-Black.woff2') format('woff2'),
    url('#{$app-base-path}/fonts/Poppins-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('#{$app-base-path}/fonts/Poppins-Bold.woff2') format('woff2'),
    url('#{$app-base-path}/fonts/Poppins-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

:root {
  --sl-legacy-background-blue: #{$legacy-background-color};
  --sl-background-blue: #{$background-blue-color};
  --sl-color-matte-blue: #{$matte-blue-color};
  --sl-font-sans: #{$font-family};
  --sl-legacy-text-gray: #{$text-gray};
  --sl-text-gray: #{$text-light-gray};
  --sl-color-primary-600: #{$primary-color-600};
  --sl-color-primary-500: #{$primary-color-500};
  --color-blue-text: #{$info-color};
  --rounded-radius: #{$round-radius};
  --card-bg: #{$active-nav-color};
  --card-border: 1px solid #{$border-color};
}

html,
body {
  font-family: $font-family;
  background-color: #fcfcfc;
}
/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7f7f7f99;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7f7f7f;
}

//Shoelace Overrides

sl-button {
  --sl-font-weight-semibold: 600;
}

sl-input,
sl-select,
sl-textarea,
.file-upload {
  --sl-input-help-text-font-size-medium: 0.75rem;
  --sl-input-label-font-size-medium: 0.875rem;
  --sl-input-color: var(--sl-text-gray);
  --sl-input-color-focus: var(--sl-text-gray);
  --sl-input-help-text-color: #919aa3;
  --sl-input-label-font-weight-medium: 500;
  --sl-input-border-color: #d8dcde;

  /* Tag Styles */
  --sl-color-neutral-50: transparent;
  --sl-color-neutral-200: #788da4;
  --sl-color-neutral-800: var(--sl-text-gray);

  &[aria-invalid='true'] {
    --sl-input-label-color: var(--sl-color-danger-500);
    --sl-input-color: var(--sl-color-danger-500);
    --sl-input-color-focus: var(--sl-color-danger-500);
    --sl-input-color-hover: var(--sl-color-danger-500);
    --sl-input-help-text-color: var(--sl-color-danger-500);
    --sl-input-focus-ring-color: #{$invalid-color};
    --sl-input-border-color-focus: #{$invalid-color};
    --sl-input-border-color-hover: #{$invalid-color};
    --sl-input-border-color: #{$invalid-color};
  }

  &::part(input) {
    font-size: 0.875rem;
    clip-path: none !important;
  }

  &::part(input)::placeholder,
  &::part(display-label) {
    font-size: 0.8125rem;
    color: var(--sl-input-help-text-color);
  }

  &::part(form-control-label) {
    font-weight: var(--sl-input-label-font-weight-medium);
  }

  &::part(form-control-help-text) {
    margin-top: 5px;
  }
  &::part(tags) {
    font-weight: 500;
    padding: 10px 12px;
    margin-inline-start: 0px;
  }

  &::part(tag) {
    padding: 0px;
  }
}

sl-menu-item {
  --sl-color-neutral-100: var(--sl-color-primary-600);

  &::part(label) {
    font-size: 0.875rem;
  }

  &:hover {
    &::part(label) {
      color: #ffffff;
    }
  }

  &:focus-visible {
    &::part(label) {
      color: #ffffff;
    }
  }
}

//Secondary Button
sl-button[variant='neutral'] {
  --sl-color-neutral-0: #505458;
  --sl-color-neutral-500: #a4afbd; //secondary button
  --sl-color-neutral-600: #dfe3e8; //secondary button
}

//Tooltip
sl-tooltip {
  --sl-tooltip-arrow-size: 0;
  --sl-tooltip-background-color: rgba(31, 31, 31, 0.9);
  --sl-tooltip-color: #e3e3e3;

  &::part(body) {
    font-size: 0.75rem;
  }
}
